<template>
  <div>
    <div class="max-w-full mx-auto">
      <div class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
        <action-component
            path="/it/roles">
          Roles
          <template v-slot:description></template>
        </action-component>
        <action-component
            path="/it/users">
          Usuarios
          <template v-slot:description></template>
        </action-component>
      </div>
    </div>
  </div>
</template>

<script>
import SubMenu from "../components/It/SubMenu";
import ActionComponent from "@/components/Common/Menu/ActionComponent";

export default {
  name: "IT",
  components: {
    ActionComponent,
    SubMenu
  },

}
</script>