<template>
  <div>
    <div class="max-w-full mx-auto py-6 sm:px-6 lg:px-8 grid gap-5 grid-cols-1 md:grid-cols-1">
      <div>
        <CardWithHeaderComponent ref="new_mailing">
          <template v-slot:header>
            <div class="flex w-full">
              <h1 class="flex w-10/12 text-lg leading-6 font-semibold text-gray-900">
                Usuarios
              </h1>
              <div class="flex w-full md:w-2/12">
                <button @click="user.showNewUser.value = true"
                  class="w-full h-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                  Nuevo Usuario
                </button>
              </div>
            </div>
          </template>
          <div class="bg-white overflow-hidden sm:rounded-md md:p-8">
            <DxDataGrid :data-source="processedUsersData" :column-auto-width="true" :hover-state-enabled="true"
              :allow-column-resizing="true" :show-borders="true">
              <DxSelection mode="multiple" />
              <DxExport :enabled="true" :allow-export-selected-data="true" />
              <DxSearchPanel :visible="true" :width="240" :margin-top="10" :padding-top="1" placeholder="Buscar..." />
              <DxToolbar>
                <DxItem location="after" name="searchPanel" />
                <DxItem location="after" name="exportButton" />
              </DxToolbar>
              <DxColumn dataField="name" caption="Usuario" alignment="center"></DxColumn>
              <DxColumn dataField="email" caption="Email" alignment="center"></DxColumn>
              <DxColumn dataField="roles" caption="Roles" alignment="center"></DxColumn>
              <DxColumn type="buttons" :width="50">
                <DxButton hint="Editar" icon="edit" @click="editUser($event.row.data)" />
              </DxColumn>
            </DxDataGrid>
          </div>
        </CardWithHeaderComponent>
        <TransitionRoot as="template" :show="user.showNewUser.value">
          <Dialog as="div" class="fixed inset-0 overflow-hidden" @close="user.showNewUser.value = false">
            <div class="absolute inset-0 overflow-hidden">
              <DialogOverlay class="absolute inset-0" />
              <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
                <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enter-from="translate-x-full" enter-to="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0"
                  leave-to="translate-x-full">
                  <div class="w-screen max-w-2xl shadow-2xl">
                    <div class="h-screen bg-gray-50 px-4 py-5">
                      <div class="px-4 py-6">
                        <div class="flex items-start justify-between space-x-3">
                          <div class="space-y-1">
                            <DialogTitle class="text-lg font-medium text-gray-900">
                              Crear Usuario
                            </DialogTitle>
                            <p class="text-sm text-gray-500"></p>
                          </div>
                          <div class="h-7 flex items-center">
                            <button type="button" class="text-gray-400 hover:text-gray-500"
                              @click="user.showNewUser.value = false">
                              <span class="sr-only">Cerrar</span>
                              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                  d="M6 18L18 6M6 6l12 12" />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="px-4 py-6 text-sm font-medium text-gray-500">
                        <div class="text-sm font-medium text-gray-500">
                          Nombre
                          <input type="text"
                            class="flex w-full px-4 py-2 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                            v-model="userName" />
                        </div>
                      </div>
                      <div class="px-4 py-6 text-sm font-medium text-gray-500">
                        <div class="text-sm font-medium text-gray-500">
                          Email
                          <input type="text"
                            class="flex w-full px-4 py-2 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                            v-model="userEmail" />
                        </div>
                      </div>
                      <div class="px-4 py-6 text-sm font-medium text-gray-500">
                        <div class="text-sm font-medium text-gray-500">
                          Password
                          <input type="text"
                            class="flex w-full px-4 py-2 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                            v-model="userPassword" />
                        </div>
                      </div>
                      <div class="px-3 text-sm font-medium text-gray-500">
                        <div class="flex w-full text-center p-2">
                          <button @click="newUser"
                            class="w-full h-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            Crear
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </TransitionChild>
              </div>
            </div>
          </Dialog>
        </TransitionRoot>
        <TransitionRoot as="template" :show="user.showEditUser.value">
          <Dialog as="div" class="fixed inset-0 overflow-hidden" @close="user.showEditUser.value = false">
            <div class="absolute inset-0 overflow-hidden">
              <DialogOverlay class="absolute inset-0" />
              <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
                <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700"
                                 enter-from="translate-x-full" enter-to="translate-x-0"
                                 leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0"
                                 leave-to="translate-x-full">
                  <div class="w-screen max-w-2xl shadow-2xl">
                    <div class="h-screen bg-gray-50 px-4 py-5">
                      <div class="px-4 py-6">
                        <div class="flex items-start justify-between space-x-3">
                          <div class="space-y-1">
                            <DialogTitle class="text-lg font-medium text-gray-900">
                              Editar usuario
                            </DialogTitle>
                            <p class="text-sm text-gray-500"></p>
                          </div>
                          <div class="h-7 flex items-center">
                            <button type="button" class="text-gray-400 hover:text-gray-500"
                                    @click="user.showEditUser.value = false">
                              <span class="sr-only">Cerrar</span>
                              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                                   stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="M6 18L18 6M6 6l12 12" />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="px-4 py-6 text-sm font-medium text-gray-500">
                        <div class="text-sm font-medium text-gray-500">
                          Nueva contraseña
                          <input v-model="newPassword" class="flex w-full px-4 py-2 leading-normal bg-white border
                      border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline
                      focus:border-blue-600"/>
                        </div>
                      </div>
                      <div class="px-3 text-sm font-medium text-gray-500">
                        <div class="flex w-full text-center p-2">
                          <button @click="updatePasswordToUser"
                                  class="w-full h-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            Actualizar
                          </button>
                        </div>
                      </div>
                      <div class="px-4 py-6 text-sm font-medium text-gray-500">
                        <div class="text-sm font-medium text-gray-500">
                          Nuevo rol
                          <select v-model="rolId" class="flex w-full px-4 py-2 leading-normal bg-white border
                      border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline
                      focus:border-blue-600">
                            <option v-for="(rol, index) in rol.rolesData.value" :value="rol.id">
                              {{ rol.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="px-3 text-sm font-medium text-gray-500">
                        <div class="flex w-full text-center p-2">
                          <button @click="addRolToUser"
                                  class="w-full h-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            Añadir
                          </button>
                        </div>
                      </div>
                      <div class="bg-white overflow-hidden sm:rounded-md md:p-8">
                        <DxDataGrid :data-source="roles" :column-auto-width="true" :show-borders="true"
                                    :allow-column-resizing="true" :hover-state-enabled="true">
                          <DxSelection mode="multiple" />
                          <DxExport :enabled="true" :allow-export-selected-data="true" />
                          <DxSearchPanel :visible="true" :width="240" :margin-top="10" :padding-top="1" placeholder="Buscar..." />
                          <DxColumn dataField="name" caption="Roles" alignment="center"></DxColumn>
                          <DxColumn type="buttons" :width="50">
                            <DxButton hint="Eliminar" icon="trash" @click="deleteRolFromUser($event.row.data)" />
                          </DxColumn>
                        </DxDataGrid>
                      </div>
                    </div>
                  </div>
                </TransitionChild>
              </div>
            </div>
          </Dialog>
        </TransitionRoot>
      </div>
    </div>
  </div>
</template>

<script>
import SubMenu from "@/components/It/SubMenu";
import CardWithHeaderComponent from "@/components/Layout/Panels/CardWithHeaderComponent";
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { useUser } from "@/use/user";
import { useRol } from "@/use/rol";
import {
  DxButton,
  DxColumn,
  DxDataGrid,
  DxExport,
  DxItem,
  DxSearchPanel,
  DxSelection,
  DxToolbar
} from "devextreme-vue/data-grid";

export default {
  name: "Users",
  data() {
    return {
      id: '',
      userName: '',
      userEmail: '',
      userPassword: '',
      newPassword: '',
      rolId: '',
      roles: []
    }
  },
  components: {
    DxButton,
    DxItem, DxToolbar,
    SubMenu,
    CardWithHeaderComponent,
    DxSelection,
    DxDataGrid,
    DxColumn,
    DxExport,
    DxSearchPanel,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot
  }, computed: {
    processedUsersData() {
      return this.user.usersData.value.map(user => {
        return {
          id: user.id,
          name: user.name,
          email: user.email,
          roles: user.roles.map(rol => rol.name).join(', '),
          rolesData: user.roles
        };
      });
    }
  },
  setup(props, context) {
    return {
      user: useUser(),
      rol: useRol()
    }
  },
  methods: {
    newUser() {
      this.user.createUser({
        name: this.userName,
        email: this.userEmail,
        password: this.userPassword,
      })
    },
    editUser(data){
      console.log(data)
      this.user.showEditUser.value = true;
      this.id = data.id
      this.roles = data.rolesData
    },
    updatePasswordToUser() {
      this.user.updatePasswordToUser({
        id: this.id,
        password: this.newPassword
      })
    },
    addRolToUser() {
      this.user.addRolToUser({
        id: this.id,
        rol: this.rolId
      })
    },
    deleteRolFromUser(data) {
      console.log(data)
      this.user.deleteRolFromUser({
        id: this.id,
        rol: data.id
      })
    },
  },
  created() {
    this.user.getAllUsers()
    this.rol.getAllRoles()
  }
}
</script>