
import store from '@/store'

export default {

    showError(errorMessage){

        let errorNotification = [
            {
                'message': errorMessage,
                'type': "error"
            }
        ]

        console.log("ErrorMessage", errorNotification)
        store.dispatch('sendNotification',  errorNotification)
        store.dispatch('removeNotificationFromTimeout')
    },

    showErrorArray(errors){

        var errorNotification = []

        errors.forEach(error => {
            console.log(error);

            errorNotification.push(
                {
                    'message': error,
                    'type': "error"
                }
            )
        });
   
        console.log("ErrorMessages", errorNotification)
        store.dispatch('sendNotification', errorNotification)
        store.dispatch('removeNotificationFromTimeout')

    },
    showErrorResponseFromAPI(error){

        if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            if(error.response.data.errors !== undefined){
                this.showErrorArray(error.response.data.errors)
            }else if(error.response.data.error !== undefined){
                this.showError(error.response.data.error)
            }else{
                this.showError(error.response.data.message)
            }
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log("No response", error.request);
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
        }
    }

}