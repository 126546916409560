<template>
</template>

<script>
import {useUser} from "../use/user";

export default {
  name: "Dashboard",
  components: {
  },
  setup(props, context) {
    return {
      user: useUser()
    }
  },
  methods: {
    showIfHavePermissions(permission){
      return this.user.permissions.value ? !!this.user.permissions.value.filter(e => e.name === permission).length : false
    }
  }
}
</script>

<style scoped>

</style>