import { reactive, computed } from 'vue'
import { useRouter } from 'vue-router'
import store from '@/store/index.js'

export function useClients() {
    const router = useRouter()

    const clientsData = computed(
        () => store.getters.getClientsData
    )

    const filters = reactive({
        id: '',
        nombre: '',
        poblacion: '',
        telefono: '',
        dni: '',
        page: 1,
        email: ''
    })

    const showLoader = reactive({
        value: false
    })
    function searchClientsByFilters(page = 1){
        this.showLoader.value = true
        this.filters.page = page

        store
            .dispatch('getClientsByFilters', filters)
            .then(
                () => {
                    this.showLoader.value = false
                }
            )
    }


    return {
        clientsData,
        filters,
        showLoader,
        searchClientsByFilters,
    }
}