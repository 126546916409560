import customAxios from '@/axios/index'
import store from '@/store'
import errorReporting from './ErrorReporting.js'

const apiClient = customAxios

export default {
    index(){
        return apiClient
            .post('/users')
            .then(({ data }) => {
                console.log('USERS', data)
                store.commit('SET_USERS_DATA', data)
            })
            .catch(error => {

                errorReporting.showErrorResponseFromAPI(error)
            })
    },
    getPermissions(){
        return apiClient
            .post('/permissions')
            .then(({ data }) => {
                store.commit('SET_PERMISSIONS_DATA', data)
            })
            .catch(error => {
                errorReporting.showErrorResponseFromAPI(error)
            })
    },
    createUser(user){
        return apiClient
            .post('/register',user)
            .then(({ data }) => {
                console.log(data)
                let notification = [
                    {
                        'message': 'Usuario creado correctamente',
                        'type': "success"
                    }
                ]
                store.dispatch('sendNotification',  notification)
                store.dispatch('removeNotificationFromTimeout')
                this.index()
            })
            .catch(error => {

                errorReporting.showErrorResponseFromAPI(error)
            })
    },
    updatePasswordToUser(data){
        return apiClient
            .post('/user/update/password',data)
            .then(({ data }) => {
                console.log(data)
                let notification = [
                    {
                        'message': 'Contraseña actualizada correctamente',
                        'type': "success"
                    }
                ]
                store.dispatch('sendNotification',  notification)
                store.dispatch('removeNotificationFromTimeout')
                this.index()
            })
            .catch(error => {

                errorReporting.showErrorResponseFromAPI(error)
            })
    },
    addRolToUser(data){
        return apiClient
            .post('/user/rol/add',data)
            .then(({ data }) => {
                console.log(data)
                let notification = [
                    {
                        'message': 'Rol añadido correctamente',
                        'type': "success"
                    }
                ]
                store.dispatch('sendNotification',  notification)
                store.dispatch('removeNotificationFromTimeout')
                this.index()
            })
            .catch(error => {

                errorReporting.showErrorResponseFromAPI(error)
            })
    },
    deleteRolFromUser(data){
        return apiClient
            .post('/user/rol/delete',data)
            .then(({ data }) => {
                console.log(data)
                let notification = [
                    {
                        'message': 'Rol eliminado correctamente',
                        'type': "success"
                    }
                ]
                store.dispatch('sendNotification',  notification)
                store.dispatch('removeNotificationFromTimeout')
                this.index()
            })
            .catch(error => {

                errorReporting.showErrorResponseFromAPI(error)
            })
    }
}
