<template>
  <div v-on:click="toggle" class="inline-flex items-center">
    <!-- On: "bg-green-600", Off: "bg-gray-200" -->
    <span role="checkbox" tabindex="0" aria-checked="false" v-bind:class="{'bg-green-600': isActive, 'bg-gray-200' : !isActive}" class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline">
            <!-- On: "translate-x-5", Off: "translate-x-0" -->
            <span aria-hidden="true" v-bind:class="{'translate-x-5': isActive, 'translate-x-0' : !isActive}" class="relative inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200">
                <!-- On: "opacity-0 ease-out duration-100", Off: "opacity-100 ease-in duration-200" -->
                <span v-bind:class="{'opacity-0 ease-out duration-100': isActive, 'opacity-100 ease-in duration-200' : !isActive}" class="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity">
                  <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                    <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </span>

              <!-- On: "opacity-100 ease-in duration-200", Off: "opacity-0 ease-out duration-100" -->
                <span v-bind:class="{'opacity-100 ease-in duration-200': isActive, 'opacity-0 ease-out duration-100' : !isActive}" class="opacity-0 ease-out duration-100 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity">
                  <svg class="h-3 w-3 text-green-600" fill="currentColor" viewBox="0 0 12 12">
                    <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                  </svg>
                </span>
            </span>
        </span>
  </div>
</template>

<script>
export default {
  name: "ToggleComponent",
  props: {
    status: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      isActive: false
    }
  },
  mounted() {
    this.isActive = this.$props.status
  },
  watch: {
    status: function (newVal) {
      this.isActive = newVal;
    },
  },
  methods: {
    toggle() {
      this.isActive = !this.isActive
    }
  }
}
</script>

<style scoped>
</style>
© 2022