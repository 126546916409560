import ClientsManagementService from "../../../services/Clients/ClientsManagementService";

export default {
    state: {
        data: {
            clients: [],
            numberOfClients: 0,
            numberOfPages: 0,
            clientsPerPages: 0
        },
    },
    mutations: {
        SET_CLIENTS_DATA (state, clientsData) {
            state.data = clientsData
        },
    },
    actions: {
        index({ commit }){
            return ClientsManagementService.index()
        },
        getClientsByFilters({ commit }, filters){
            return  ClientsManagementService.index(filters)
        },
        storeClients({ commit }, ClientsObject){
            commit('SET_CLIENTS_DATA', ClientsObject)
        }
    },
    getters: {
        getClientsData(state){
            console.log('CLIENTS GETTER',state.data)

            return state.data
        },
    }
};