import AuthenticationService from '@/services/Authentication.js'
import UserService from "../../services/UserService";

export default {
    state: {
        id: {

        },
        user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : '',
        email: localStorage.getItem('userEmail') ?? ''
    },
    mutations: {
        SET_USER_DATA (state, userData) {
            state.user = userData
            console.log("User data", userData)
            localStorage.setItem('user', JSON.stringify(userData))

            let accessToken = userData.access_token
            AuthenticationService.setToken(accessToken)
        },
        CLEAR_USER_DATA (){

            /**
             *
             * Vuex State does not survive a browser refresh, it takes care of clearing our user State for us.
             * Additionally, it clears our axios Authorization header as well.
             *
             * */

            localStorage.removeItem('user')
            location.reload()
        },
        SET_PERMISSIONS_DATA (state, permissionsData) {
            state.user.permissions = permissionsData
            localStorage.setItem('user', JSON.stringify(state.user))
        },
        SET_USER_EMAIL (state, userEmail) {
            state.email = userEmail
            console.log("User email", userEmail);
            localStorage.setItem('userEmail', userEmail)
        },
    },
    actions: {

        login ({ commit }, credentials) {
            return AuthenticationService.login(credentials)
        },

        logout({ commit }){
            commit('CLEAR_USER_DATA')
        },

        getPermissions ({ commit }) {
            return UserService.getPermissions()
        },

        newUser ({ commit }, user) {
            return UserService.createUser(user)
        },
        updatePasswordToUser ({ commit }, data) {
            return UserService.updatePasswordToUser(data)
        },
        addRolToUser ({ commit }, data) {
            return UserService.addRolToUser(data)
        },
        deleteRolFromUser ({ commit }, data) {
            return UserService.deleteRolFromUser(data)
        },
    },
    getters: {
        isLogged(state){
            console.log("User",state.user);
            console.log("Entrada isLogged",!!state.user);
            return !!state.user;
        },
        getAccessToken(state){
            return state.user.access_token
        },
        getUserEmail(state){
            console.log('USER EMAIL GETTER',state.email)
            return state.email
        },
        getUserPermissions(state){
            return state.user.permissions
        }
    }
};