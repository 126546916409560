import ClientsManagementService from "../../../services/Clients/ClientsManagementService";

const getDefaultState = () => {
    return {
        id: null,
        email: '',
        telefono: '',
        nombre: '',
        dni: '',
        direccion: '',
        poblacion: '',
        provincia: '',
        cp: '',
    };
};

export default {
    state: {
        data: getDefaultState(),
        errorsForm: '',
    },
    mutations: {
        SET_CLIENT_DATA(state, clientData) {
            state.data = clientData
        },
        SET_CLIENT_FORM_ERRORS(state, ClientErrorsForm) {
            state.errorsForm = ClientErrorsForm
        },
        CLEAR_ERRORS(state) {
            state.errorsForm = ''
        },

    },
    actions: {
        getClientData({ commit }, code) {
            return ClientsManagementService.getClientData(code)
        },
        saveClientData({ commit }, clientData) {
            return ClientsManagementService.saveClientData(clientData)
        },
        addClientData({ commit }, clientData) {
            return ClientsManagementService.addClient(clientData)
        },
        storeClient({ commit }, ClientObject) {
            commit('SET_CLIENT_DATA', ClientObject)
        },
        clearErrors({ commit }) {
            commit('CLEAR_ERRORS')
        },
        storeClientFormErrors({ commit }, clientErrorsForm) {
            console.log("storeClientFormErrors",clientErrorsForm);
            commit('SET_CLIENT_FORM_ERRORS', clientErrorsForm)
        },
    },
    getters: {
        getClientData(state) {
            console.log('CLIENT GETTER', state.data)
            return state.data
        },
        getClientFormErrors(state) {
            console.log('CLIENT FORM ERRORS', state.errorsForm)
            return state.errorsForm
        },

    }
};