<template>
  <!--
    This example requires Tailwind CSS v2.0+

    This example requires some changes to your config:

    ```
    // tailwind.config.js
    module.exports = {
      // ...
      plugins: [
        // ...
        require('@tailwindcss/forms'),
      ],
    }
    ```
  -->
  <div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img class="mx-auto h-12 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="Workflow">
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
        Accede con tu cuenta
      </h2>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <form class="space-y-6" @submit.prevent="login">
          <div>
            <label for="email" class="block text-sm font-medium text-gray-700">
              Email usuario
            </label>
            <div class="mt-1">
              <input v-model="email" v-bind:class="{ 'border-red-500' : !isValidate }" id="email" name="email" type="email" autocomplete="email" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
            </div>
          </div>

          <div>
            <label for="password" class="block text-sm font-medium text-gray-700">
              Contraseña
            </label>
            <div class="mt-1">
              <input v-model="pass" v-bind:class="{ 'border-red-500' : !isValidate }" id="password" name="password" type="password" autocomplete="current-password" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <p v-show="!isValidate" class="text-red-500 text-xs italic">Por favor, introduce una contraseña valida.</p>
            </div>
          </div>

          <div class="flex items-center justify-between">
            <div class="text-sm">
              <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">
                ¿Has olvidado tu contraseña?
              </a>
            </div>
          </div>

          <div>
            <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Acceder
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorReporting from '../services/ErrorReporting.js'

export default {
  name: "Login",
  data() {
      return {
          email: '',
          pass: '',
          isValidate: true,

          errors: []
      }
  },
  methods: {
      login(){
          this.isValidate = true
          this.errors = []

          console.log("Entrada");
          if(this.validateLogin()){
            console.log('')
              this.$store
                  .dispatch('login', {
                      email: this.email,
                      password: this.pass
                  })
                  .then(() => { this.$router.push({ name: 'Dashboard' }) })
          }
      },
      validateLogin(){
          console.log("Entrada");

          let email = this.email;
          let pass = this.pass;

          if(!email){
              this.errors.push('Debe introducir un email');
          }

          if(!pass){
              this.errors.push('Debe introducir una pass');
          }

          console.log("Login.vue", this.errors);

          if(this.errors.length != 0){
              this.isValidate = false;
              ErrorReporting.showErrorArray(this.errors)
          }

          return this.isValidate;
      }
  }
}
</script>

<style scoped>

</style>