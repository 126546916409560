import { createRouter, createWebHistory } from 'vue-router'
import Login from "../views/Login";
import Dashboard from "../views/Dashboard";
import ClientsManagement from "../views/clients/ClientsManagement";
import ClientManagement from "../views/clients/ClientManagement";
import ClientAdd from "../views/clients/ClientAdd";
import ProvidersManagement from "../views/providers/ProvidersManagement";
import ProviderManagement from "../views/providers/ProviderManagement";
import ProviderAdd from "../views/providers/ProviderAdd";
import IT from "../views/IT";
import Roles from "../views/it/Roles";
import Users from "../views/it/Users";


const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
        meta: {requiresAuth: true}
    },
    {
        path: '/clients',
        name: 'ClientsManagement',
        component: ClientsManagement,
        meta: {
            requiresAuth: true,
            permission: 'clients.show'
        }
    },
    {
        path: '/clients/add/',
        name: 'ClientAdd',
        component: ClientAdd,
        meta: {
            requiresAuth: true,
            permission: 'clients.show'
        }
    },
    {
        path: '/clients/edit/:id',
        name: 'ClientManagement',
        component: ClientManagement,
        meta: {
            requiresAuth: true,
            permission: 'clients.show'
        }
    },
    {
        path: '/providers',
        name: 'ProvidersManagement',
        component: ProvidersManagement,
        meta: {
            requiresAuth: true,
            permission: 'providers.show'
        }
    },
    {
        path: '/providers/add/',
        name: 'ProviderAdd',
        component: ProviderAdd,
        meta: {
            requiresAuth: true,
            permission: 'providers.show'
        }
    },
    {
        path: '/providers/edit/:id',
        name: 'ProviderManagement',
        component: ProviderManagement,
        meta: {
            requiresAuth: true,
            permission: 'providers.show'
        }
    },
    {
        path: '/it',
        name: 'IT',
        component: IT,
        meta: {
            requiresAuth: true,
            permission: 'it.show'
        }
    },
    {
        path: '/it/roles',
        name: 'Roles',
        component: Roles,
        meta: {
            requiresAuth: true,
            permission: 'it.show'
        }
    },
    {
        path: '/it/users',
        name: 'Users',
        component: Users,
        meta: {
            requiresAuth: true,
            permission: 'it.show'
        }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    const isLogged = localStorage.getItem('user')


    /**
     * Here we are saying if the route being navigated to matches one of our routes (record)
     * where the meta tag includes requiresAuth = true, then we’ll do something
     */

    if (to.matched.some(record => record.meta.requiresAuth) && !isLogged) {
        next('/login')
    } else if (isLogged && (to.name === 'Login')) {
        next('/')
    } else {
        if (to.meta.permission === 'undefined' && to.name !== 'Dashboard') {
            console.error('Se necesita meter permisos para acceder a ' + to.name + ' en el router')
        }
        if (
            (to.meta.permission !== 'undefined' && isLogged && JSON.parse(isLogged).permissions.filter(e => e.name === to.meta.permission).length && (to.name !== 'Dashboard'))
            ||
            (to.name === 'Dashboard')
            ||
            (to.name === 'Login')
        ) {
            next()
        } else if (to.name !== 'Dashboard') {
            next('/')
        }
    }

})

export default router
