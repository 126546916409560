import Axios from "axios";
import store from "@/store";

const customAxios = Axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    },
    timeout: 240000
});

customAxios.interceptors.request.use(function (config) {
    //config.data['agency_id'] = store.getters.getAgencyID;
    return config;
}, function (error) {
    return Promise.reject(error);
});

/**
 * Definimos un interceptor para todas las peticiones axios
 * De esta forma evitamos que un agente malicioso pueda meter
 * un token falso en localstorage y entrar en la página.
 * En el momento en que se realice la primera peticion con un token
 * falso, el interceptor deslogueara al usuario
 */

customAxios.interceptors.response.use(
    response => response,
    error => {
        if(error.response.status == 401){
            store.dispatch('logout')
        }

        return Promise.reject(error)
    })

let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : ''
let token = user.access_token || ''

customAxios.defaults.headers.common['Authorization'] = `Bearer ${
    token
}`

export default customAxios