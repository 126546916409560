import customAxios from '@/axios/index'
import store from '@/store'
import errorReporting from './ErrorReporting.js'

const authApiClient = customAxios

export default {
    login(authObject){
        return authApiClient
            .post('/login',authObject)
            .then(({ data }) => {
                store.commit('SET_USER_EMAIL', authObject.email)
                store.commit('SET_USER_DATA', data)
            })
            .catch(error => {

                errorReporting.showErrorResponseFromAPI(error)

            })
    },
    setToken(token){
        authApiClient.defaults.headers.common['Authorization'] = `Bearer ${
            token
        }`
    },
    removeToken(){
        authApiClient.defaults.headers.common['Authorization'] = null
    }
}
