import UserService from '@/services/UserService.js'

export default {
    state: {
        users: []
    },
    mutations: {
        SET_USERS_DATA (state, usersData) {
            state.users = usersData
            console.log("Users data", usersData)
        },
    },
    actions: {
        getAllUsers ({ commit }) {
            return UserService.index()
        },
    },
    getters: {
        getUsers(state){
            console.log('USERS GETTER',state.users)
            return state.users
        }
    }
};