<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="mb-6">
    <div class="px-2 pb-2 text-lg">
      <slot name="header"></slot>
    </div>
    <div class="bg-white overflow-hidden rounded-lg shadow">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardWithHeaderComponent',
  props: {
    loading: {
      type: Boolean,
      default: true
    }
  }
}
</script>