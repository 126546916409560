import customAxios from '@/axios/index'
import store from '@/store'
import errorReporting from '@/services/ErrorReporting.js'
import router from '@/router';

const apiClient = customAxios


export default {
    index(filters){
        return apiClient
            .post('/clients',filters)
            .then(({ data }) => {
                console.log("Respuesta Api ", data)
                store.dispatch('storeClients', data)
            })
            .catch(error => {
                errorReporting.showErrorResponseFromAPI(error)
            })
    },

    getClientData(code){
        let path = '/client/' + code

        return apiClient
            .get(path)
            .then(({ data }) => {
                console.log("Respuesta Api ", data)
                store.dispatch('storeClient', data)
            })
            .catch(error => {
                errorReporting.showErrorResponseFromAPI(error)
            })
    },

    saveClientData(clientData){
        return apiClient
            .post('/client/update',clientData)
            .then(({ data }) => {
                console.log("Respuesta Api ", data)
                let notification = [
                    {
                        'message': 'Cliente actualizado correctamente',
                        'type': "success"
                    }
                ]

                store.dispatch('sendNotification',  notification)
                store.dispatch('removeNotificationFromTimeout')
                store.dispatch('clearErrors')
            })
            .catch(error => {
                store.dispatch('storeClientFormErrors', error.response.data.errors)
            })
    },

    addClient(clientData){
        return apiClient
            .post('/client/add',clientData)
            .then(({ data }) => {
                let notification = [
                    {
                        'message': 'Cliente creado correctamente',
                        'type': "success"
                    }
                ]
                store.dispatch('sendNotification', notification);

                const clientId = data.data.id;
                router.push(`/clients/edit/${clientId}`);
                store.dispatch('removeNotificationFromTimeout');
                store.dispatch('clearErrors')

            })
            .catch(error => {
                store.dispatch('storeClientFormErrors', error.response.data.errors)
            })
    },

}

