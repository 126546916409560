<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div>
    <nav class="bg-gray-800">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex items-center justify-between h-16">
          <div class="flex items-center">
            <div class="hidden md:block">
              <div class="ml-10 flex items-baseline space-x-4">
                <router-link
                    to="/it/roles"
                    v-bind:class="{
                      'bg-gray-900 text-white': isActive('Roles'),
                      'text-gray-300 hover:bg-gray-700 hover:text-white': !isActive('Roles')
                    }"
                    class="px-3 py-2 rounded-md text-sm font-medium"
                >
                  Roles
                </router-link>
                <router-link
                    to="/it/users"
                    v-bind:class="{
                      'bg-gray-900 text-white': isActive('Users'),
                      'text-gray-300 hover:bg-gray-700 hover:text-white': !isActive('Users')
                    }"
                    class="px-3 py-2 rounded-md text-sm font-medium"
                >
                  Users
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Mobile menu, show/hide based on menu state. -->
      <div class="md:hidden" id="mobile-menu">
        <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">

          <router-link to="/booking/books" class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Reservas</router-link>

        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "SubMenu",
  components: {
  },
  data(){
    return {
      showProfileMenu: false,
      showMainMenu: false
    }
  },
  methods:{
    logout(){
      this.$store
          .dispatch('logout')
          .then(() => { this.$router.push({ name: 'Login' }) })
    },
    isActive(route){
      return this.$route.name === route
    }
  }
}
</script>

<style scoped>

</style>