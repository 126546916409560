import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './index.css'
import VueApexCharts from "vue3-apexcharts"
import VCalendar from 'v-calendar'
import { library } from '@fortawesome/fontawesome-svg-core'
import 'devextreme/dist/css/dx.light.css';
import 'v-calendar/dist/style.css';

import { faUserSecret, faStar, faHotel, faBuilding, faHome, faPercent, faCube, faWifi, faFan, faDumbbell, faUtensils, faTv, faConciergeBell, faWheelchair, faParking, faQuestion, faExclamationCircle, faSearch, faFilter, faEdit, faTrashAlt,
    faCreditCard, faInfoCircle, faStickyNote, faExclamationTriangle, faKey, faPhotoVideo, faEnvelopeSquare, faFilePdf, faPrint, faFilm, faHistory, faPlus, faMinus, faTimesCircle, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faUserSecret, faStar, faHotel, faBuilding, faHome, faPercent, faCube, faWifi, faFan, faDumbbell, faUtensils, faTv, faConciergeBell, faWheelchair, faParking ,faQuestion, faExclamationCircle, faSearch, faFilter, faEdit, faTrashAlt,
    faCreditCard, faInfoCircle, faStickyNote, faExclamationTriangle, faKey, faPhotoVideo, faEnvelopeSquare, faFilePdf, faPrint, faFilm, faHistory, faPlus, faMinus, faTimesCircle, faSpinner)


const app = createApp(App)

/**
 * Registramos font awesome globalmente
 * ya que las vamos a usar en todo el proyecto
 */

app.component('font-awesome-icon', FontAwesomeIcon)

app.use(store)
app.use(router)
app.use(VueApexCharts)
app.use(VCalendar)
app.mount('#app')