<template>
  <div>
    <div class="max-w-full mx-auto py-6 sm:px-6 lg:px-8 grid gap-5 grid-cols-1 md:grid-cols-1">
      <div>
        <CardWithHeaderComponent ref="new_mailing">
          <template v-slot:header>
            <div class="flex w-full">
              <h1 class="flex w-10/12 text-lg leading-6 font-semibold text-gray-900">
                Roles
              </h1>
              <div class="flex w-full md:w-2/12">
                <button @click="showNewRol.value = true"
                  class="w-full h-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                  Nuevo rol
                </button>
              </div>
            </div>
          </template>
          <div class="bg-white overflow-hidden sm:rounded-md md:p-8">
            <DxDataGrid :data-source="processedRolesData" :column-auto-width="true" :show-borders="true"
              :allow-column-resizing="true" :hover-state-enabled="true">
              <DxSelection mode="multiple" />
              <DxExport :enabled="true" :allow-export-selected-data="true" />
              <DxSearchPanel :visible="true" :width="240" :margin-top="10" :padding-top="1" placeholder="Buscar..." />
              <DxColumn dataField="rolName" caption="Roles" alignment="center"></DxColumn>
            </DxDataGrid>
          </div>
        </CardWithHeaderComponent>
        <TransitionRoot as="template" :show="showNewRol.value">
          <Dialog as="div" class="fixed inset-0 overflow-hidden" @close="showNewRol.value = false">
            <div class="absolute inset-0 overflow-hidden">
              <DialogOverlay class="absolute inset-0" />
              <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
                <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enter-from="translate-x-full" enter-to="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0"
                  leave-to="translate-x-full">
                  <div class="w-screen max-w-2xl shadow-2xl">
                    <div class="h-screen bg-gray-50 px-4 py-5">
                      <div class="px-4 py-6">
                        <div class="flex items-start justify-between space-x-3">
                          <div class="space-y-1">
                            <DialogTitle class="text-lg font-medium text-gray-900">
                              Crear rol
                            </DialogTitle>
                            <p class="text-sm text-gray-500"></p>
                          </div>
                          <div class="h-7 flex items-center">
                            <button type="button" class="text-gray-400 hover:text-gray-500"
                              @click="showNewRol.value = false">
                              <span class="sr-only">Cerrar</span>
                              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                  d="M6 18L18 6M6 6l12 12" />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="px-4 py-6 text-sm font-medium text-gray-500">
                        <div class="text-sm font-medium text-gray-500">
                          Rol
                          <input type="text"
                            class="flex w-full px-4 py-2 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                            v-model="rolName" />
                        </div>
                      </div>
                      <div class="px-3 text-sm font-medium text-gray-500">
                        <div class="flex w-full text-center p-2">
                          <button @click="newRol"
                            class="w-full h-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            Crear
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </TransitionChild>
              </div>
            </div>
          </Dialog>
        </TransitionRoot>
      </div>
    </div>
  </div>
</template>

<script>
import SubMenu from "@/components/It/SubMenu";
import CardWithHeaderComponent from "@/components/Layout/Panels/CardWithHeaderComponent";
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { useRol } from "@/use/rol";
import { DxColumn, DxDataGrid, DxExport, DxSearchPanel, DxSelection } from "devextreme-vue/data-grid";

export default {
  name: "Roles",
  components: {
    DxExport,
    DxSelection,
    DxSearchPanel,
    DxColumn,
    DxDataGrid,
    SubMenu,
    CardWithHeaderComponent,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot
  },
  data() {
    return {
      rolName: ''
    }
  }, computed: {
    processedRolesData() {
      return this.rolesData.map(role => {
        return {
          rolName: role.name,
        };
      });
    }
  },
  setup(props, context) {
    return useRol()
  },
  methods: {
    newRol() {
      this.newRol(this.rolName)
    }
  },
  created() {
    this.getAllRoles()
  }
}
</script>