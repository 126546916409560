import {computed, reactive} from 'vue'
import store from '@/store/index.js'

export function useUser() {

    const email = computed(
        () => store.getters.getUserEmail
    )

    const usersData = computed(
        () => store.getters.getUsers
    )

    const permissions = computed(
        () => store.getters.getUserPermissions
    )

    const showNewUser = reactive({
        value: false
    })

    const showEditUser = reactive({
        value: false
    })

    function getAllUsers(){
        store
            .dispatch('getAllUsers')

    }

    function getPermissions(){
        store
            .dispatch('getPermissions')

    }

    function createUser(user){
        store
            .dispatch('newUser', user)
            .then(
                () => {
                    this.showNewUser.value = false
                }
            )
    }

    function updatePasswordToUser(data){
        store
            .dispatch('updatePasswordToUser', data)
            .then(
                () => {
                    this.showEditUser.value = false
                }
            )
    }

    function addRolToUser(data){
        store
            .dispatch('addRolToUser', data)
            .then(
                () => {
                    this.showEditUser.value = false
                }
            )
    }

    function deleteRolFromUser(data){
        store
            .dispatch('deleteRolFromUser', data)
            .then(
                () => {
                    this.showEditUser.value = false
                }
            )
    }

    return {
        email,
        usersData,
        permissions,
        showNewUser,
        showEditUser,
        getAllUsers,
        getPermissions,
        createUser,
        updatePasswordToUser,
        addRolToUser,
        deleteRolFromUser
    }
}