import RolService from '@/services/RolService.js'

export default {
    state: {
        roles: []
    },
    mutations: {
        SET_ROLES_DATA (state, rolesData) {
            state.roles = rolesData
            console.log("Roles data", rolesData)
        },
    },
    actions: {
        getAllRoles ({ commit }) {
            return RolService.index()
        },
        newRol ({ commit }, rol) {
            return RolService.create(rol)
        },
    },
    getters: {
        getRoles(state){
            console.log('ROLES GETTER',state.roles)
            return state.roles
        }
    }
};