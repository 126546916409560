import {computed, reactive} from 'vue'
import store from '@/store/index.js'

export function useRol() {

    const rolesData = computed(
        () => store.getters.getRoles
    )

    const showNewRol = reactive({
        value: false
    })

    function getAllRoles(){
        store.dispatch('getAllRoles')
    }

    function newRol(rol){
        store.dispatch('newRol', rol)
    }

    return {
        rolesData,
        showNewRol,
        getAllRoles,
        newRol
    }
}