import ProvidersManagementService from "../../../services/Providers/ProvidersManagementService";

export default {
    state: {
        data: {
            providers: [],
            numberOfProviders: 0,
            numberOfPages: 0,
            providersPerPages: 0
        },
    },
    mutations: {
        SET_PROVIDERS_DATA (state, providersData) {
            state.data = providersData
        },
    },
    actions: {
        index({ commit }){
            return ProvidersManagementService.index()
        },
        getProvidersByFilters({ commit }, filters){
            return  ProvidersManagementService.index(filters)
        },
        storeProviders({ commit }, ProvidersObject){
            commit('SET_PROVIDERS_DATA', ProvidersObject)
        }
    },
    getters: {
        getProvidersData(state){
            console.log('PROVIDERS GETTER',state.data)

            return state.data
        },
    }
};