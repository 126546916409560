import { reactive, computed, watch  } from 'vue'
import { useRouter } from 'vue-router'
import store from '@/store/index.js'


export function useClient() {
    const router = useRouter()

    const clientData = computed(
        () => store.getters.getClientData
    )

    const clientFormErrors = computed(
        () => store.getters.getClientFormErrors
    )

    const showLoader = reactive({
        value: false
    })

    async function getClientData(code) {
        this.showLoader.value = true
        await store
            .dispatch('getClientData', code)
            .then(
                () => {
                    this.showLoader.value = false
                }
            )
    }

    function saveClientData() {
        this.showLoader.value = true
        store
            .dispatch('saveClientData', this.clientData.value)
            .then(
                () => {
                    this.showLoader.value = false
                }
            )
    }

    function addClientData() {
        this.showLoader.value = true
        console.log("DATOS A ENVIAR",this.clientData.value);
        store
            .dispatch('addClientData', this.clientData.value)
            .then(
                () => {
                    this.showLoader.value = false

                }
            )
    }

    return {
        clientData,
        showLoader,
        getClientData,
        saveClientData,
        addClientData,
        clientFormErrors,
    }
}