import { createStore } from 'vuex'
import notification from '@/store/modules/notification.js'
import user from '@/store/modules/user.js'
import users from '@/store/modules/users.js'
import clients from '@/store/modules/Clients/clients.js'
import client from '@/store/modules/Clients/client.js'
import providers from '@/store/modules/Providers/providers.js'
import provider from '@/store/modules/Providers/provider.js'
import roles from '@/store/modules/roles.js'
export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    notification,
    user,
    users,
    clients,
    client,
    providers,
    provider,
    roles,
  }
})
