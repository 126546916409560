import { reactive, computed, watch  } from 'vue'
import { useRouter } from 'vue-router'
import store from '@/store/index.js'


export function useProvider() {
    const router = useRouter()

    const providerData = computed(
        () => store.getters.getProviderData
    )

    const providerFormErrors = computed(
        () => store.getters.getProviderFormErrors
    )

    const showLoader = reactive({
        value: false
    })

    async function getProviderData(code) {
        this.showLoader.value = true
        await store
            .dispatch('getProviderData', code)
            .then(
                () => {
                    this.showLoader.value = false
                }
            )
    }

    function saveProviderData() {
        this.showLoader.value = true
        store
            .dispatch('saveProviderData', this.providerData.value)
            .then(
                () => {
                    this.showLoader.value = false
                }
            )
    }

    function addProviderData() {
        this.showLoader.value = true
        console.log("DATOS A ENVIAR",this.providerData.value);
        store
            .dispatch('addProviderData', this.providerData.value)
            .then(
                () => {
                    this.showLoader.value = false

                }
            )
    }

    return {
        providerData,
        showLoader,
        getProviderData,
        saveProviderData,
        addProviderData,
        providerFormErrors,
    }
}