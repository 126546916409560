<template>
  <div class="hover:bg-blue-50 relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
    <div>
      <h3 class="text-lg font-medium">
        <router-link
            :to="path" class="focus:outline-none">
          <!-- Extend touch target to entire panel -->
          <span class="absolute inset-0" aria-hidden="true"></span>
          <slot></slot>
        </router-link>
      </h3>
      <p class="mt-2 text-sm text-gray-500">
        <slot name="description"></slot>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ActionComponent",
  props: {
    path: {
      type: String,
      default: '#'
    }
  }
}
</script>

<style scoped>

</style>