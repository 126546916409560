<template>
    <Listbox  @update:modelValue="updateSelectedItem">
        <div class="relative mt-1">
            <ListboxButton
                class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                <span class="flex items-center">
                    <div v-html="selectedItem.avatar"></div>
                    <span class="ml-3 block truncate">{{ selectedItem.name }}</span>
                </span>
                <span class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                    <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
            </ListboxButton>
            <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
                leave-to-class="opacity-0">
                <ListboxOptions
                    class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    <ListboxOption as="template" v-for="item in items" :key="item.id" :value="item"
                        v-slot="{ active, selectedItem }">
                        <li
                            :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                            <div class="flex items-center">
                                <div v-html="item.avatar"></div>
                                <span :class="[selectedItem ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']">{{
                                    item.name
                                }}</span>
                            </div>

                            <span v-if="selectedItem"
                                :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                            </span>
                        </li>
                    </ListboxOption>
                </ListboxOptions>
            </transition>
        </div>
    </Listbox>
</template>
  
<script>
import { ref } from 'vue';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue';
//import { ChevronUpDownIcon, CheckIcon } from '@headlessicons/vue';

export default {
    components: {
        Listbox,
        ListboxButton,
        ListboxOption,
        ListboxOptions,
    },
    props: {
        items: Object, // Array of items to display in the dropdown
        selectedItem: Object, // The currently selected item
    },
    methods: {
    updateSelectedItem(newValue) {
      this.$emit('update:selectedItem', newValue);
      this.$emit('update:model', newValue.id);

    },
  },


};
</script>