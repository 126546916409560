<template>
  <teleport to="body">
    <Loader v-if="client.showLoader.value" />
  </teleport>
  <header class="bg-white shadow-sm">
    <div class="flex max-w-full mx-auto py-4 px-4 sm:px-6 lg:px-8">
      <h1 class="text-lg leading-6 font-semibold text-gray-900">
        Gestión de Clientes
      </h1>
    </div>
  </header>

  <div class="container m-auto grid grid-cols-3 gap-4 py-4">
    <div class="col-span-3 md:col-span-3 lg:col-span-2">
      <div class="overflow-hidden bg-white shadow sm:rounded-lg">
        <div class="container m-auto grid grid-cols-3 gap-4 px-4 py-5 sm:px-6">
          <div class="col-span-2">
            <h3 class="text-base font-semibold leading-6 text-gray-900">Datos Clientes</h3>
            <p class="mt-1 max-w-2xl text-sm text-gray-500">Información y detalles</p>
          </div>

          <div class="col-span-1 justify-self-end">
            <button type="submit" @click="save"
                    class="h-50 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
              Guardar
            </button>
          </div>
        </div>

        <div class="border-t border-gray-200">
          <dl>
            <div class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500 py-3">CIF/DNI/NIE</dt>
              <input
                  class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                  type="text" v-model="client.clientData.value.dni" />
              <dt v-if="client.clientFormErrors.value.dni" class="text-sm font-medium text-gray-500 py-3 text-red-600">
                {{ client.clientFormErrors.value.dni[0] }}
              </dt>
            </div>
            <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500 py-3">Nombre</dt>
              <input required
                  class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                  type="text" v-model="client.clientData.value.nombre" />
              <dt v-if="client.clientFormErrors.value.nombre" class="text-sm font-medium text-gray-500 py-3 text-red-600">
                {{ client.clientFormErrors.value.nombre[0] }}
              </dt>
            </div>
            <div class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500 py-3">Domicilio</dt>
              <input required
                  class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                  type="text" v-model="client.clientData.value.direccion" />
              <dt v-if="client.clientFormErrors.value.direccion" class="text-sm font-medium text-gray-500 py-3 text-red-600">
                {{ client.clientFormErrors.value.direccion[0] }}
              </dt>
            </div>
            <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500 py-3">C.P.</dt>
              <input required
                  class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                  type="text" v-model="client.clientData.value.cp" />
              <dt v-if="client.clientFormErrors.value.cp" class="text-sm font-medium text-gray-500 py-3 text-red-600">
                {{ client.clientFormErrors.value.cp[0] }}
              </dt>
            </div>
            <div class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500 py-3">Provincia</dt>
              <input
                  class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                   type="text" v-model="client.clientData.value.provincia" />
            </div>
            <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500 py-3">Población</dt>
              <input required
                  class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                  type="text" v-model="client.clientData.value.poblacion" />
              <dt v-if="client.clientFormErrors.value.poblacion" class="text-sm font-medium text-gray-500 py-3 text-red-600">
                {{ client.clientFormErrors.value.poblacion[0] }}
              </dt>
            </div>
            <div class="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500 py-3">Teléfono</dt>
              <input required
                  class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                  type="text" v-model="client.clientData.value.telefono" />
              <dt v-if="client.clientFormErrors.value.telefono" class="text-sm font-medium text-gray-500 py-3 text-red-600">
                {{ client.clientFormErrors.value.telefono[0] }}
              </dt>
            </div>
            <div class="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500 py-3">Email</dt>
              <input required
                  class="text-gray-700 w-full py-2 px-4 leading-normal bg-white border border-gray-300 rounded appearance-none focus:outline-none focus:shadow-outline"
                  type="email" v-model="client.clientData.value.email" />
              <dt v-if="client.clientFormErrors.value.email" class="text-sm font-medium text-gray-500 py-3 text-red-600">
                {{ client.clientFormErrors.value.email[0] }}
              </dt>
            </div>
          </dl>
        </div>
      </div>
    </div>
   </div>
 </template>

 <script>
 import { useClient } from "@/use/client";

 import {defineAsyncComponent, reactive, ref} from "vue";

 const Loader = defineAsyncComponent(() => import("@/components/Common/Loader/Loader.vue"))
 import CardWithHeaderComponent from "@/components/Layout/Panels/CardWithHeaderComponent";
 import ToggleComponent from "../../components/Common/Form/ToggleComponent";
 import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
 import Pagination from "../../components/Pagination";
 import Notification from "@/App.vue";
 import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
 import CustomSelectInput from "../../components/Common/Select/CustomSelectInput";
 import moment from "moment";
 import ConfirmationAlertDelete from '../../components/Common/Confirmation/ConfirmationAlertDelete.vue';

 import {useUser} from "../../use/user";


 export default {
   name: "agenciesManagement",
   components: {
     Notification,
     Pagination,
     Loader,
     CardWithHeaderComponent,
     ToggleComponent,
     Dialog,
     DialogOverlay,
     DialogTitle,
     TransitionChild,
     TransitionRoot,
     CustomSelectInput,
     Listbox, ListboxButton, ListboxOption, ListboxOptions,
     ConfirmationAlertDelete

   },
   setup(props, context) {
     const alert = reactive({
       show: false,
     });

     return {
       user: useUser(),
       client: useClient(),
       alert,
     }
   },
   data() {
     return {
       formatter: new Intl.NumberFormat('es-ES', {
         style: 'currency',
         currency: 'EUR'
       }),
     }
   },
   methods: {
     save() {
       this.client.saveClientData()
     },

     getDateInFormat(date) {
       return moment(date).format('DD/MM/YYYY HH:mm');
     },

     showIfHavePermissions(permission) {
       return this.user.permissions.value ? !!this.user.permissions.value.filter(e => e.name === permission).length : false
     },

   },
   async created() {
     await this.client.getClientData(this.$route.params.id)
   },
   mounted() {
   },


 }
 </script>