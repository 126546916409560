export default {
    state: {
        notificationMessages: []
    },
    mutations: {
        SET_NOTIFICATION (state, notificationMessages) {
            state.notificationMessages = notificationMessages
        },
        REMOVE_NOTIFICATION (state, timeout){
            for(let i=0; i<state.notificationMessages.length; i++){
                setTimeout(() => {
                    state.notificationMessages.shift()
                }, timeout);
            }
        }
    },
    actions: {
        sendNotification({ commit}, notificationMessages ) {
            commit('SET_NOTIFICATION', notificationMessages)
        },
        removeNotificationFromTimeout({ commit}, timeout=4000){
            commit('REMOVE_NOTIFICATION',timeout)
        }
    },
    getters: {
        getNotificationMessages(state){
            return state.notificationMessages
        }
    }
};