import customAxios from '@/axios/index'
import store from '@/store'
import errorReporting from '@/services/ErrorReporting.js'
import router from '@/router';

const apiClient = customAxios


export default {
    index(filters){
        return apiClient
            .post('/providers',filters)
            .then(({ data }) => {
                console.log("Respuesta Api ", data)
                store.dispatch('storeProviders', data)
            })
            .catch(error => {
                errorReporting.showErrorResponseFromAPI(error)
            })
    },

    getProviderData(code){
        let path = '/provider/' + code

        return apiClient
            .get(path)
            .then(({ data }) => {
                console.log("Respuesta Api ", data)
                store.dispatch('storeProvider', data)
            })
            .catch(error => {
                errorReporting.showErrorResponseFromAPI(error)
            })
    },

    saveProviderData(providerData){
        return apiClient
            .post('/provider/update',providerData)
            .then(({ data }) => {
                console.log("Respuesta Api ", data)
                let notification = [
                    {
                        'message': 'Proveedor actualizado correctamente',
                        'type': "success"
                    }
                ]

                store.dispatch('sendNotification',  notification)
                store.dispatch('removeNotificationFromTimeout')
                store.dispatch('clearErrors')
            })
            .catch(error => {
                store.dispatch('storeProviderFormErrors', error.response.data.errors)
            })
    },

    addProvider(providerData){
        return apiClient
            .post('/provider/add',providerData)
            .then(({ data }) => {
                let notification = [
                    {
                        'message': 'Proveedor creado correctamente',
                        'type': "success"
                    }
                ]
                store.dispatch('sendNotification', notification);

                const providerId = data.data.id;
                router.push(`/providers/edit/${providerId}`);
                store.dispatch('removeNotificationFromTimeout');
                store.dispatch('clearErrors')

            })
            .catch(error => {
                store.dispatch('storeProviderFormErrors', error.response.data.errors)
            })
    },

}

