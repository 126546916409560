<template>
  <div class="bg-white overflow-hidden sm:rounded-md md:p-8">
    <ul role="list" class="divide-y divide-gray-200">
      <li class="hidden md:block bg-gray-500 text-white">
        <div class="px-2 py-2 sm:px-4">
          <div class="sm:flex w-full sm:justify-between">
            <div class="flex w-full items-center text-center">
              <p class="w-full">Numero de clientes: {{ clients.clientsData.value.numberOfClients }}</p>
            </div>
          </div>
        </div>
      </li>
      <li class="hidden md:block bg-gray-600 text-white">
        <div class="px-4 py-4 sm:px-6">
          <div class="sm:flex w-full sm:justify-between">
            <div class="flex w-full items-center text-center">
              <p class="w-1/3 text-left font-bold">Nombre</p>
              <p class="w-1/3 text-left font-bold">Dirección</p>
              <p class="w-1/3 text-left font-bold">Teléfono</p>
              <p class="w-1/12 text-center font-bold">Acciones</p>
            </div>
          </div>
        </div>
      </li>
      <li v-for="(client,index) in clients.clientsData.value.clients" :key="index" class="text-gray-500 text-sm">
        <div class="px-4 py-4 sm:px-6">
          <div class="sm:flex w-full sm:justify-between">
            <div class="md:flex w-full items-center">
              <p class="w-full mb-2 md:mb-0 md:w-1/3 text-left">
                {{ client.nombre }}
              </p>
              <p class="w-full mb-2 md:mb-0 md:w-1/3 text-left">
                {{ client.direccion }}
              </p>
              <p class="w-full mb-2 md:mb-0 md:w-1/3 text-left">
                {{ client.telefono }}
              </p>
              <p class="flex w-full mb-2 md:mb-0 md:w-1/12 text-center justify-center">
                <button title="Editar cliente"  class="flex w-1/2 justify-center" @click="editClient(client.id)">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                  </svg>
                </button>
              </p>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import {useClients} from "@/use/clients";
import {useUser} from "@/use/user";
import moment from "moment";
import router from "../../router";

export default {
  name: "clientManagementResult",
  setup(props, context) {
    return {
      clients: useClients(),
      user: useUser(),
    };
  },
  methods: {
    getDateInFormat(date){
      return moment(date).format('DD/MM/YYYY')
    },
    editClient(id){
      router.push({name: 'ClientManagement', params: {id: id}})
    },

    showIfHavePermissions(permission) {
      return this.user.permissions.value ? !!this.user.permissions.value.filter(e => e.name === permission).length : false
    }
  }
}
</script>

<style scoped>

</style>