<template>
  <div class="top-12 right-6 z-50 fixed content-center w-auto h-auto">
    <transition-group name="list-fade" tag="div" appear>
      <div v-for="notificationMessage in getNotificationMessages" :key="notificationMessage" :class="getStylesFromNotificationType(notificationMessage.type)" class="block w-full h-auto rounded-md p-4 mb-2">
        <div class="flex">
          <div class="flex-shrink-0">
            <!-- Heroicon name: information-circle -->
            <svg v-if="notificationMessage.type==='error'" class="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
            </svg>

            <svg v-else-if="notificationMessage.type==='warning'" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
            </svg>

            <svg v-else class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
            </svg>
          </div>
          <div class="ml-3 flex-1 md:flex md:justify-between items-center">
            <p class="text-sm">
              {{notificationMessage.message}}
            </p>
            <!--<p class="mt-3 text-sm md:mt-0 md:ml-6">
              <a href="#" class="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600">Details <span aria-hidden="true">&rarr;</span></a>
            </p>-->
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { notificationComputed } from '@/store/helpers.js'

export default {
  name: "Notification",
  data(){
    return {
    }
  },
  methods: {
    getStylesFromNotificationType(notificationType){
      switch (notificationType){
        case 'success':
          return "text-green-700 bg-green-50"
        case 'error':
          return "text-red-700 bg-red-50"
        case 'warning':
          return "text-yellow-700 bg-yellow-50"
      }
    }
  },
  computed:{
    ...notificationComputed
  }
};
</script>

<style scoped>
.error {
  background-color:red;
  color: white;
}
.list-fade-enter-active, .list-fade-leave-active {
  transition: all 1s;
}
.list-fade-enter-from{
  opacity: 0;
  transform: translateY(-30px);
}
.list-fade-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>
