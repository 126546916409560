<template>
  <home></home>
  <notification></notification>
</template>

<style>
</style>

<script>
import Home from '@/views/Home.vue'
import Notification from "@/components/Common/Notification/Notification";
import UserService from "./services/UserService";

export default {
  name: "App",
  components: {
    Home,
    Notification
  },
  created() {
    setInterval(() => {
      const isLogged = localStorage.getItem('user')
      if(isLogged){
        UserService.getPermissions()
      }
    }, (1000 * 60));
  }
}
</script>