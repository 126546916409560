import customAxios from '@/axios/index'
import store from '@/store'
import errorReporting from './ErrorReporting.js'

const apiClient = customAxios

export default {
    index(){
        return apiClient
            .post('/roles')
            .then(({ data }) => {
                console.log('ROLES', data)
                store.commit('SET_ROLES_DATA', data)
            })
            .catch(error => {

                errorReporting.showErrorResponseFromAPI(error)
            })
    },
    create(rol){
        return apiClient
            .post('/rol/create',{name: rol})
            .then(({ data }) => {
                console.log('ROLES', data)
                store.commit('SET_ROLES_DATA', data)
            })
            .catch(error => {

                errorReporting.showErrorResponseFromAPI(error)
            })
    }
}
