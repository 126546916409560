<template>
  <div class="bg-gray-100">
    <nav v-show="isLogged" class="bg-purple-800">
      <div class="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex items-center justify-between h-16">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <span class="text-white">Pipas</span>
            </div>
            <div class="hidden md:block">
              <div class="ml-10 flex items-baseline space-x-4">
                <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
                <router-link to="/" v-bind:class="{
                  'bg-gray-900 text-white': isActive(['Dashboard']),
                  'text-gray-300 hover:bg-gray-700 hover:text-white': !isActive(['Dashboard'])
                }" class="px-3 py-2 rounded-md text-sm font-medium">
                  Dashboard
                </router-link>

                <router-link v-show="showIfHavePermissions('clients.show')" to="/clients" v-bind:class="{
                  'bg-gray-900 text-white': isActive(['ClientsManagement', 'ClientManagement', 'ClientAdd']),
                  'text-gray-300 hover:bg-gray-700 hover:text-white': !isActive(['ClientsManagement', 'ClientManagement', 'ClientAdd'])
                }" class="px-3 py-2 rounded-md text-sm font-medium">
                  Clientes
                </router-link>

                <router-link v-show="showIfHavePermissions('providers.show')" to="/providers" v-bind:class="{
                  'bg-gray-900 text-white': isActive(['Providers']),
                  'text-gray-300 hover:bg-gray-700 hover:text-white': !isActive(['Providers'])
                }" class="px-3 py-2 rounded-md text-sm font-medium">
                  Proveedores
                </router-link>

                <router-link v-show="showIfHavePermissions('products.show')" to="/products" v-bind:class="{
                  'bg-gray-900 text-white': isActive(['Products']),
                  'text-gray-300 hover:bg-gray-700 hover:text-white': !isActive(['Products'])
                }" class="px-3 py-2 rounded-md text-sm font-medium">
                  Productos
                </router-link>

                <router-link v-show="showIfHavePermissions('it.show')" to="/it" v-bind:class="{
                  'bg-gray-900 text-white': isActive(['IT', 'Roles', 'Users']),
                  'text-gray-300 hover:bg-gray-700 hover:text-white': !isActive(['IT', 'Roles', 'Users'])
                }" class="px-3 py-2 rounded-md text-sm font-medium">
                  Panel IT
                </router-link>
              </div>
            </div>
          </div>
          <div class="hidden md:block">
            <div class="ml-4 flex items-center md:ml-6">
              <!-- Profile dropdown -->
              <div class="ml-3 relative">
                <div class="flex" @mouseover="showProfileMenu = true" @mouseleave="showProfileMenu = false">
                  <div v-show="showProfileMenu"
                       class="mr-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                       role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
                    <!-- Active: "bg-gray-100", Not Active: "" -->

                    <a @click="logout" href="#" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1"
                       id="user-menu-item-2">Cerrar sesión</a>
                  </div>
                  <div>
                    <button type="button"
                            class="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                      id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                      <span class="sr-only">Open user menu</span>
                      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                           stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="-mr-2 flex md:hidden">
            <!-- Mobile menu button -->
            <button type="button" @click="showMainMenu = !showMainMenu"
              class="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
              aria-controls="mobile-menu" aria-expanded="false">
              <span class="sr-only">Open main menu</span>
              <!--
                Heroicon name: outline/menu

                Menu open: "hidden", Menu closed: "block"
              -->
              <svg class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
              <!--
                Heroicon name: outline/x

                Menu open: "block", Menu closed: "hidden"
              -->
              <svg class="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <!-- Mobile menu, show/hide based on menu state. -->
      <div class="md:hidden" id="mobile-menu">
        <div v-show="showMainMenu" class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
          <router-link to="/"
            class="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium">Dashboard</router-link>

          <a href="/clients"
            class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Clientes</a>

          <a href="/providers"
            class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Proveedores</a>

          <a href="/products"
            class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Productos</a>

          <router-link v-show="showIfHavePermissions('it.show')" to="/it"
            class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Panel IT</router-link>

          <div class="pt-3 border-t border-gray-700 mt-3">
            <a @click="logout"
               class="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700">Cerrar
              sesión</a>
          </div>
        </div>
      </div>
    </nav>

    <router-view />
  </div>
</template>

<script>
// @ is an alias to /src
import { authComputed } from '@/store/helpers.js'
import { useUser } from "../use/user";

export default {
  name: 'Home',
  components: {
  },
  setup(props, context) {
    return {
      user: useUser()
    }
  },
  data() {
    return {
      showProfileMenu: false,
      showMainMenu: false
    }
  },
  methods: {
    logout() {
      this.$store
        .dispatch('logout')
        .then(() => { this.$router.push({ name: 'Login' }) })
    },
    isActive(routes) {
      return routes.includes(this.$route.name)
    },
    showIfHavePermissions(permission) {
      return this.user.permissions.value ? !!this.user.permissions.value.filter(e => e.name === permission).length : false
    }
  },
  computed: {
    ...authComputed
  }
}
</script>