<template>
  <div v-show="numberofelements > 0" class="flex flex-col items-center my-12">
    <div class="flex text-gray-700">
      <div v-show="currentpage > 1" @click="goToPage(currentpage - 1)" class="h-12 w-12 mr-1 flex justify-center items-center rounded-full bg-gray-200 cursor-pointer">
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left w-6 h-6">
          <polyline points="15 18 9 12 15 6"></polyline>
        </svg>
      </div>
      <div @click="goToPage(1)" class="w-12 md:flex justify-center items-center hidden h-12 font-medium rounded-full cursor-pointer leading-5 transition duration-150 ease-in rounded-full" v-bind:class="{'bg-gray-600 bg-teal-600 text-white': isActivePage(1)}">1</div>
      <div v-show="(currentpage) > 3" class="w-12 md:flex justify-center items-center hidden h-12 font-medium rounded-full cursor-pointer leading-5 transition duration-150 ease-in rounded-full">...</div>
      <div v-for="page in (numberofpages)" class="flex h-12 font-medium rounded-full">
        <div v-if="isShowPage(page)" @click="goToPage(page)" class="w-12 md:flex justify-center items-center hidden  cursor-pointer leading-5 transition duration-150 ease-in rounded-full" v-bind:class="{'bg-gray-600 bg-teal-600 text-white': isActivePage(page)}">{{ page }}</div>
      </div>
      <div v-show="((currentpage) < (numberofpages - 2))" class="w-12 md:flex justify-center items-center hidden h-12 font-medium rounded-full cursor-pointer leading-5 transition duration-150 ease-in rounded-full">...</div>
      <div v-show="numberofpages > 1" @click="goToPage(numberofpages)" class="w-12 md:flex justify-center items-center hidden h-12 font-medium rounded-full cursor-pointer leading-5 transition duration-150 ease-in rounded-full" v-bind:class="{'bg-gray-600 bg-teal-600 text-white': isActivePage(numberofpages)}">{{ numberofpages }}</div>
      <div v-show="(currentpage) < (numberofpages)" @click="goToPage(currentpage + 1)" class="h-12 w-12 ml-1 flex justify-center items-center rounded-full bg-gray-200 cursor-pointer">
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right w-6 h-6">
          <polyline points="9 18 15 12 9 6"></polyline>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    currentpage: {
      default: 1
    },
    numberofpages: {
      default: 1
    },
    numberofelements: {
      default: 0
    }
  },
  methods: {
    isActivePage(page){
      return page === this.currentpage
    },
    isShowPage(page){
      return ((page === (this.currentpage - 1)) || (page === (this.currentpage + 1))  || (page === (this.currentpage))) && page !== 1 && page !== this.numberofpages
    },
    goToPage(page){
      this.$parent.goToPage(page)
    }
  }
}
</script>

<style scoped>

</style>